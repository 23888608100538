import formatCurrency from '../../../../helpers/formatCurrency';

export default (compareAtPrice, variantPrice = '0', locale, currency, pricingDecimals=null) => {
  const percentageDiscountPrice = parseFloat(variantPrice) - (parseFloat(variantPrice) * parseFloat(compareAtPrice.value) / 100);
  const fixedAmountDiscountPrice = parseFloat(variantPrice) - parseFloat(compareAtPrice.value);

  return (
    compareAtPrice?.sym && compareAtPrice.value !== "" ?
      compareAtPrice.sym === "%" ?
        formatCurrency(percentageDiscountPrice < 0 ? 0 : percentageDiscountPrice, locale, currency, pricingDecimals)
        :
        formatCurrency(fixedAmountDiscountPrice < 0 ? 0 : fixedAmountDiscountPrice, locale, currency, pricingDecimals)
      :
      formatCurrency(variantPrice, locale, currency, pricingDecimals)
  )
}